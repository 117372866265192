import React, {useState, useEffect} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {
    adjustDateToBrowserTimeZone, calculateDaysDifference,
    formatDateString,
    formatDateToAmericanFormat,
    getOnlyDate
} from "../../utils/utils";
import Config from "../../core/config";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const ProductStatistics = () => {
    let [productStatistics, setProductStatistics] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [, setLoading] = useRecoilState(loading);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/products/product-statistics?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}`;
            const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
            setProductStatistics(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery('');
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleQuery = async () => {
        await makeQuery();
    };

    function getBackgroundColor(productStatistics) {

        const errorColor = 'var(--error-color)';
        const warningColor = 'var(--warning-color)';
        const normalColor = 'var(--normal-green-color)';
        const percentage = (productStatistics.stock*productStatistics.productCostValue) > 0 ? productStatistics.totalRentalValue / (productStatistics.stock*productStatistics.productCostValue) : 1;
        if (percentage >= 1) {
            return normalColor;
        }

        if(percentage > 0.75) {
            return warningColor;
        }
        return errorColor;

        return undefined;
    }

    function copyToClipboard() {
        let clipboardText = `Código\tNome do Produto\tEstoque\tCusto do Produto\tLocações\tValor Locações\n`;
        clipboardText += productStatistics.map(product =>
            `${product.code}\t${product.productName}\t${product.stock}\t${(product.stock * product.productCostValue).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}\t${product.numberOfRentals}\t${product.totalRentalValue.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`).join('\n');

        navigator.clipboard.writeText(clipboardText)
            .then(() => {
                alert('Estatística de produtos copiada para a área de transferência.');
            })
            .catch(err => {
                console.error('Erro ao copiar para a área de transferência: ', err);
            });
    }

    return (
        <>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div>
                    <div className="header-label">Estatísticas de Produtos</div>
                </div>
                <button onClick={copyToClipboard}>Copiar</button>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        Período:
                        <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>

                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                            <button onClick={handleQuery}>Consultar</button>
                        </div>
                    </div>
                </div>
            </div>
            <table style={{width: '800px', height: '380px'}}>
                <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '340px'}}/>
                    <col style={{width: '100px'}}/>
                    <col style={{width: '150px'}}/>
                    <col style={{width: '100px'}}/>
                    <col style={{width: '120px'}}/>
                </colgroup>
                <tr>
                    <th>
                        <div style={{textAlign: 'center'}}>Código</div>
                    </th>
                    <th>
                        Nome do Produto
                    </th>
                    <th>
                        <div style={{textAlign: 'center'}}>Estoque</div>
                    </th>
                    <th>
                        <div style={{textAlign: 'right'}}>Custo do Produto</div>
                    </th>
                    <th>
                        <div style={{textAlign: 'center'}}>Locações</div>
                    </th>
                    <th>
                        <div style={{textAlign: 'right'}}>Valor Locações</div>

                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {productStatistics.map((productStatistics, index) => (
                    <tr style={{backgroundColor: getBackgroundColor(productStatistics)}}>
                        <td>
                            <div style={{textAlign: 'center'}} key={index}>{productStatistics.code}</div>
                        </td>
                        <td>
                            <div key={index}>{productStatistics.productName}</div>
                        </td>
                        <td>
                            <div key={index}>{productStatistics.stock}</div>
                        </td>
                        <td>
                            <div key={index}
                                 style={{textAlign: 'right'}}>{(productStatistics.stock * productStatistics.productCostValue).toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                        <td>
                            <div key={index} style={{textAlign: 'center'}}>{productStatistics.numberOfRentals}</div>
                        </td>
                        <td>
                            <div key={index}
                                 style={{textAlign: 'right'}}>{productStatistics.totalRentalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {productStatistics.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
            </table>
        </>
    );
};

export default ProductStatistics;
